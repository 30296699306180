import React, { useEffect, useRef } from "react";
import setARState from "./arview";
import { addCustomEventListener, removeCustomEventListener } from "./events";

export default (props) => {
  const sceneRef = useRef(null);
  const sceneModelRef = useRef(null);
  const imageRef = useRef(null);

  const threeDButtonClicked = () => {
    //console.log("3D button clicked");

    props.seasonClicked("kevat");
    props.sceneClicked();
  }

  const stopArSystem = () => {
    //console.log("stopping AR system");

    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];
    arSystem.ui.scanningMask.style.display = "none";
    arSystem.stop();
    props.arStopped();
  }

  //this sends and notification to arview.js to check if AR system should be resized or not, and only if the system is already running, it triggers the "resizeNow" custom event.
  //this is necessary since Mind AR doesn't have an AR initializing state, so multiple starts would be queued if stopping/starting system with screen resize.
  const sendResizeEventToArView = () => {
    //console.log("resize check");
    props.resize();
  }

  const resizeScreenNow = () => {
    console.log("resize now");
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];


    if (arSystem != null) {
      //console.log("restarting");
      arSystem.stop();
      arSystem.start();
      props.arStopped();
    }

  }


  useEffect(() => {
    window.addEventListener("resize", sendResizeEventToArView, false);
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];

    sceneEl.addEventListener('renderstart', () => {
      console.log("render start");
      arSystem.start(); // start AR 

    });

    sceneEl.addEventListener("arReady", (event) => {
      // console.log("MindAR is ready")
      props.arLoaded();
    });
    // detect target found
    sceneEl.addEventListener("targetFound", () => {
      //console.log("target found");
      props.imageFound();
    });
    //detect target lost
    sceneEl.addEventListener("targetLost", () => {
      props.imageLost();
    });
    sceneEl.addEventListener("arError", (event) => {
      props.arError();
    });

    addCustomEventListener("threeDButtonClicked", threeDButtonClicked);
    addCustomEventListener("stopArSystem", stopArSystem);
    addCustomEventListener("resizeNow", resizeScreenNow);
    // detect click event
    sceneModelRef.current.addEventListener("click", (evt) => {

      if (sceneModelRef.current != null) {
        //console.log(sceneEl);
        let object = evt.detail.intersection.object;
        props.seasonClicked(object.parent.name);
        props.sceneClicked();
        arSystem.stop();

        window.removeEventListener("resize", sendResizeEventToArView, false);
      }

    });
  }, []);

  return (

    <a-scene ref={sceneRef} shadow="type: basic" mindar-image="imageTargetSrc: targets.mind; autoStart: false; uiLoading: no; uiError: no; uiScanning: yes;" color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false"  >
      <a-assets>
        <a-asset-item id="scene3D" src="arObject.glb"></a-asset-item>
      </a-assets>
      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="far: 10000; objects: .clickable"></a-camera>
      <a-entity light="type: ambient; color: #fff; intensity: 0.75"></a-entity>
      <a-entity light="type: directional; castShadow: true;  shadowMapHeight: 512; shadowMapWidth: 512;  intensity: 0.75" position="5000 3000 1500"></a-entity>
      <a-entity ref={imageRef} mindar-image-target="targetIndex: 0" animation-mixer>
        <a-gltf-model ref={sceneModelRef} shadow="receive: true, cast:true" id="scenethreeD" class="clickable" rotation="90 0 0 " position="0 0 0" scale="1 1 1" src="#scene3D" gltf-model=""></a-gltf-model>
      </a-entity>
    </a-scene>
  )
};
