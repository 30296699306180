import React from "react";
import languageData from "../languages/languages.json";

class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 1 };
  }
  handleClick() {
    this.props.onSelectStart(true);
  }
  nextPage() {
    this.setState({ page: this.state.page + 1 });
    // console.log(this.state.page);
  }
  previousPage() {
    this.setState({ page: this.state.page - 1 });
    // console.log(this.state.page);
  }
  navigateLanguage() {
    this.props.onNavigateBack();
  }

  render() {
    return (
      <header className="App-content bg-backgroundcolor">
        {this.state.page == 1 && (
          <div>
            {/* <div className="flex max-w-3xl"> */}
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {"1/4"}
              </span>
            </div>
            <div className="items-center">
              <img src="01_scan.png" style={{ maxWidth: "250px", maxHeight: "250px" }} alt="" className="mx-auto" />
            </div>
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {languageData[this.props.locale].instructionText1}
              </span>
            </div>
            {/* </div> */}
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.navigateLanguage();
              }}
            >
              {languageData[this.props.locale].previousSeason}
            </button>
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.nextPage();
              }}
            >
              {languageData[this.props.locale].nextSeason}
            </button>
          </div>
        )}
        {this.state.page == 2 && (
          <div>
            {/* <div className="flex max-w-3xl"> */}
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {"2/4"}
              </span>
            </div>
            <div className="items-center">
              <img src="02_select.png" style={{ maxWidth: "250px", maxHeight: "250px" }} alt="" className="mx-auto" />
            </div>
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {languageData[this.props.locale].instructionText2}
              </span>
            </div>
            {/* </div> */}
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.previousPage();
              }}
            >
              {languageData[this.props.locale].previousSeason}
            </button>
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.nextPage();
              }}
            >
              {languageData[this.props.locale].nextSeason}
            </button>
          </div>
        )}
        {this.state.page == 3 && (
          <div>
            {/* <div className="flex max-w-3xl"> */}
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {"3/4"}
              </span>
            </div>
            <div className="items-center">
              <img src="03_info.png" style={{ maxWidth: "250px", maxHeight: "250px" }} alt="" className="mx-auto" />
            </div>
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {languageData[this.props.locale].instructionText3}
              </span>
            </div>
            {/* </div> */}
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.previousPage();
              }}
            >
              {languageData[this.props.locale].previousSeason}
            </button>
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.nextPage();
              }}
            >
              {languageData[this.props.locale].nextSeason}
            </button>
          </div>
        )}
        {this.state.page == 4 && (
          <div>
            {/* <div className="flex max-w-3xl"> */}
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {"4/4"}
              </span>
            </div>
            <div className="items-center">
              <img src="04_rotate.png" style={{ maxWidth: "250px", maxHeight: "250px" }} alt="" className="mx-auto" />
            </div>
            <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
              <span className="text-thirdcolor text-center font-light m-4">
                {languageData[this.props.locale].instructionText4}
              </span>
            </div>
            {/* </div> */}
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.previousPage();
              }}
            >
              {languageData[this.props.locale].previousSeason}
            </button>
            <button
              className="bg-maincolor text-secondarycolor p-4 m-4"
              onClick={() => {
                this.handleClick();
              }}
            >
              {languageData[this.props.locale].startArButtonText}
            </button>
          </div>
        )}
      </header>
    );
  }
}
export default Instructions;
