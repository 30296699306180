import React, { useEffect, useRef } from "react";
import BackgroundGradient from "./background-gradient.js";
import OrbitControls from "./orbit-controls.js";
import AnimationMixer from "./animation-mixer.js"
import { addCustomEventListener, removeCustomEventListener } from "./events";

export default (props) => {
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    const audio1Ref = useRef(null);
    const sceneRef = useRef(null);

    const modelRef = useRef(null);

    const camRef = useRef(null);
    
    let currentContent = null;

    const sceneLoaded = () => {
        //console.log("scene loaded")
        props.sceneLoaded(true);
    }

    const handleZoomOutButton = () => {
        //console.log("zoomout button")
        if (camRef.current) {
            //console.log("zooming out");
            camRef.current.components['orbit-controls'].zoomOut();
        }
    }
    const handleZoomInButton = () => {
        //console.log("zoomin button")
        if (camRef.current) {
            //console.log("zooming in");
            camRef.current.components['orbit-controls'].zoomIn();
        }
    }
    const handleStopAutorotation = () => {
        if (camRef.current) {
            //console.log("stopping autorotation");
            camRef.current.setAttribute("orbit-controls", "autoRotate", "false");
        }
    }
    const handleStartAutorotation = () => {

        if (camRef.current) {
            camRef.current.setAttribute("orbit-controls", "autoRotate", "true");
        }
    }
    const handleLeftButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateLeft();
        }
    }
    const handleRightButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateRight();
        }
    }
    const handleUpButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateUp();
        }
    }
    const handleDownButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateDown();
        }
    }
    const handleRemoveEventListeners = () => {
        console.log("removing event listeners");
        removeCustomEventListener("zoomIn", handleZoomInButton());
        removeCustomEventListener("zoomOut", handleZoomOutButton());
        removeCustomEventListener("stopAutoRotate", handleStopAutorotation());
        removeCustomEventListener("startAutoRotate", handleStartAutorotation());
    }
    //todo: remove event listeners
    /*     function removeEventListeners() {
            this.text1Ref.current.removeEventListener("click", this.onTextClick1);
            this.text2Ref.current.removeEventListener("click", this.onTextClick2, false);
            this.text3Ref.current.removeEventListener("click", this.onTextClick3, false);
            this.image1Ref.current.removeEventListener("click", this.onImageClick1, false);
            this.image2Ref.current.removeEventListener("click", this.onImageClick2, false);
            this.audio1Ref.current.removeEventListener("click", this.onAudioClick1, false);
        } */

    const onTextClick1 = () => {
        //console.log("Text click 1");

        props.openModal({
            type: "text",
            content: "winterText1",
        });
    }
    const onTextClick2 = () => {

        props.openModal({
            type: "text",
            content: "winterText2",
        });
    }
    const onTextClick3 = () => {
        handleStopAutorotation();
        props.openModal({
            type: "text",
            content: "winterText3",
        });
    }
    const onImageClick1 = () => {
        //console.log("image 1");
        handleStopAutorotation();
        props.openModal({
            type: "image",
            image: "emojakuutti.jpg",
            imageText: "winterText4",
            altText: "winterAlt4",
        });
    }
    const onImageClick2 = () => {

        handleStopAutorotation();
        props.openModal({
            type: "image",
            image: "apukinokset.jpg",
            imageText: "winterText5",
            altText: "winterAlt5",
        });
    }
    const onAudioClick1 = () => {
        handleStopAutorotation();
        props.openModal({
            type: "audio",
            content: "winterAudioText1",
            source: "NorppaVocal_short_UEF.mp3"
        });
    }
    const onText1Pressed = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            //event.preventDefault();
            //console.log("pressed enter");
            onTextClick1();
        }
    }
    const onText2Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick2();
        }
    }
    const onText3Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick3();
        }
    }
    const onImage1Pressed = (event) => {
        if (event.key === "Enter") {
            onImageClick1();
        }

    }
    const onImage2Pressed = (event) => {
        if (event.key === "Enter") {
            onImageClick2();
        }

    }
    const onAudio1Pressed = (event) => {
        if (event.key === "Enter") {
            onAudioClick1();
        }

    }
    const onFocusReceived = (params) => {
        //console.log("focus received");
        currentContent = document.activeElement;

        //get current #src attribute and determine which icon to display
        let src = currentContent.getAttribute("src");
        switch (src) {
            case "#iconText":
                currentContent.setAttribute("material", "src", "#iconTextSelected");
                break;
            case "#iconAudio":
                currentContent.setAttribute("material", "src", "#iconAudioSelected");
                break;
            case "#iconVideo":
                currentContent.setAttribute("material", "src", "#iconVideoSelected");
                break;
            case "#iconImage":
                currentContent.setAttribute("material", "src", "#iconImageSelected");
                break;
            default:
                console.log("not implemented! " + src);
        }
    }



    const onFocusLost = () => {
        //console.log("lost focus");

        let src = currentContent.getAttribute("src");
        //console.log(src);
        switch (src) {
            case "#iconText":
                currentContent.setAttribute("material", "src", "#iconText");
                break;
            case "#iconAudio":
                currentContent.setAttribute("material", "src", "#iconAudio");
                break;
            case "#iconVideo":
                currentContent.setAttribute("material", "src", "#iconVideo");
                break;
            case "#iconImage":
                currentContent.setAttribute("material", "src", "#iconImage");
                break;
            default:
                console.log("not implemented! " + src);
        }
    }
    useEffect(() => {
//#region  add listeners
        //add camera control event listeners
        addCustomEventListener("zoomIn", () => handleZoomInButton());
        addCustomEventListener("zoomOut", () => handleZoomOutButton());
        addCustomEventListener("stopAutoRotate", () => handleStopAutorotation());
        addCustomEventListener("startAutoRotate", () => handleStartAutorotation());
        addCustomEventListener("cameraButtonLeft", () => handleLeftButton());
        addCustomEventListener("cameraButtonRight", () => handleRightButton());
        addCustomEventListener("cameraButtonUp", () => handleUpButton());
        addCustomEventListener("cameraButtonDown", () => handleDownButton());
        sceneRef.current.addEventListener("loaded", () => sceneLoaded());

        //add click listeners
        text1Ref.current.addEventListener("click", onTextClick1, false);
        text2Ref.current.addEventListener("click", onTextClick2, false);
        text3Ref.current.addEventListener("click", onTextClick3, false);
        image1Ref.current.addEventListener("click", onImageClick1, false);
        image2Ref.current.addEventListener("click", onImageClick2, false);
        audio1Ref.current.addEventListener("click", onAudioClick1, false);

        //add keypress listeners
        text1Ref.current.addEventListener("keypress", onText1Pressed, false);
        text2Ref.current.addEventListener("keypress", onText2Pressed, false);
        text3Ref.current.addEventListener("keypress", onText3Pressed, false);
        image1Ref.current.addEventListener("keypress", onImage1Pressed, false);
        image2Ref.current.addEventListener("keypress", onImage2Pressed, false);
        audio1Ref.current.addEventListener("keypress", onAudio1Pressed, false);

        //add focus listeners
        text1Ref.current.addEventListener("focus", onFocusReceived, false);
        text2Ref.current.addEventListener("focus", onFocusReceived, false);
        text3Ref.current.addEventListener("focus", onFocusReceived, false);
        image1Ref.current.addEventListener("focus", onFocusReceived, false);
        image2Ref.current.addEventListener("focus", onFocusReceived, false);
        audio1Ref.current.addEventListener("focus", onFocusReceived, false);
        // add blur / lose focus listeners
        text1Ref.current.addEventListener("blur", onFocusLost, false);
        text2Ref.current.addEventListener("blur", onFocusLost, false);
        text3Ref.current.addEventListener("blur", onFocusLost, false);
        image1Ref.current.addEventListener("blur", onFocusLost, false);
        image2Ref.current.addEventListener("blur", onFocusLost, false);
        audio1Ref.current.addEventListener("blur", onFocusLost, false);
//#endregion

        return () => {
            window.THREE.Cache.clear();
            if (sceneRef.current != null) {
                //console.log(sceneRef.current.renderer);
                sceneRef.current.renderer.renderLists.dispose();
                sceneRef.current.renderer.dispose();
                sceneRef.current.renderer.forceContextLoss();

                //console.log("cleared");
            }
        }
    }, []);

    return (
        <a-scene ref={sceneRef} color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false" shadow="type: basic" >
            <a-assets>
                <a-asset-item id="talvi" src="sektori_talvi.glb"></a-asset-item>
                <img id="iconText" src="iconText.png" />
                <img id="iconTextSelected" src="iconTextSelected.png" />
                <img id="iconVideo" src="iconVideo.png" />
                <img id="iconVideoSelected" src="iconVideoSelected.png" />
                <img id="iconAudio" src="iconAudio.png" />
                <img id="iconAudioSelected" src="iconAudioSelected.png" />
                <img id="iconImage" src="iconImage.png" />
                <img id="iconImageSelected" src="iconImageSelected.png" />
                <a-asset-item id="norppa" src="norppa_ani_talvi.glb"></a-asset-item>
            </a-assets>

            <a-entity geometry="primitive: sphere; radius:65"
                material="shader: background-gradient; colorTop: #D7EFFF; colorBottom: #C6C6C6; side: back;"></a-entity>

            <a-camera ref={camRef} orbit-controls="
                autoRotate: true;
                autoRotateSpeed: 0.05;
                rotateSpeed: 0.05;
                target: #sector;
                enableDamping: true;
                enablePan: false;
                dampingFactor: 0.25;
                rotateSpeed:0.14;  
                zoomSpeed: 0.5;         
                minDistance: 3.75;
                maxDistance: 7;
                minPolarAngle: 0.75;
                maxPolarAngle: 1.25;
                "
                position="0 0 0" wasd-controls="enabled: false" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="far: 10000; objects: .clickable"></a-camera>

            {/* <a-entity light="type: ambient; color: #edf9fa; intensity: 0.75"></a-entity> */}
            <a-entity light="type: ambient; color: #e3f4ff; intensity: 0.75"></a-entity>
            <a-entity light="type: directional; color: #f7d9a8;  castShadow: true; shadowBias: -0.0001; shadowMapHeight: 512; shadowMapWidth: 512;  intensity: 0.75" position="-5 3 1.5"></a-entity>


            <a-entity id="sector" position="0 0 0" scale="10 10 10">
                <a-gltf-model ref={modelRef} class="clickable" shadow="receive: true; cast:true" src="#talvi" rotation="0 0 0" scale="1 1 1"></a-gltf-model>
                {/* <a-image ref={videoIconRef} shadow="receive: false, cast:false" class="clickable" src="#iconVideo"  scale=".05 .05  .05" position="0.15 0.01 0" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image> */}
                <a-image ref={text3Ref} tabIndex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="-0.15 0.01 -0.11" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text1Ref} tabIndex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="0.08 0.01 -0.14" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={audio1Ref} tabindex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconAudio" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="0.29 0.01 -0.075" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={image1Ref} tabIndex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconImage" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="0.23 0.01 0.037" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={image2Ref} tabindex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconImage" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="-0.05 0.01 0.081" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text2Ref} tabIndex="0" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" position="0.05 0.03 0.16" rotation="-90 0 0" event-set__loaded="_delay: 1500"></a-image>
            </a-entity>

            <a-entity id="norppa" position="0 0 -0.00" scale="10 10 10" shadow="receive: true, cast:true" >
                <a-gltf-model shadow="cast:true" src="#norppa" rotation="0 50 0" position="0.075 0.001 0.195" scale="0.5 0.5 0.5" animation-mixer=""></a-gltf-model>
            </a-entity>
        </a-scene>
    )
};

